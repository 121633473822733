@import '../../styles/variables';

.Reader {
  height: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
    overflow: hidden;
  }

  .Sidebar.menu-visible+.MangaReader {
    display: none;
  }
}