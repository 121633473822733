@import '../../../styles/variables';

.SidebarFooter {
  background: $background-gradient;

  ul {
    margin-bottom: 0;

    li {
      text-align: center;

      a {
        @include SidebarButton();
        display: block;
        padding: 10px;
      }
    }
  }

}