@import '../../../styles/variables';

.MangaPages {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 0 0 100%;
  margin: 0 auto;
  height: 100vh;
  min-height: 100%;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  @media (min-width: $breakpoint-md) {
    max-width: 75%;
    flex: 0 0 75%;
  }

  ul {
    padding: 5px;
    margin: 0;

    li {
      padding: 15px 0;
      margin: 0 auto;

      &:last-child {
        margin-bottom: 30px;
      }

      img {
        display: block;
        width: 100%;
        margin: 0 auto;
        opacity: 1;
        transition: all ease-in-out 0.5s;

        &[data-src],
        &.placeholder {
          opacity: 0.75;
          width: 50%;
        }

        &[data-loading] {
          width: 75%;
          animation: flickerAnimation 1s infinite;
        }
      }
    }

  }

  .ChapterButtons {
    padding: 0;
    margin: 0;
    margin-bottom: 60px;
    justify-content: center;
    align-items: center;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 30px;
    }

    .ChapterButton {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      margin: 0 auto;
      border: none;
      flex: 1;
      transition: all .35s ease-in-out;

      &.prev {
        &::after {
          content: attr(data-text);
        }
      }

      &.next {
        &::before {
          content: attr(data-text);
        }
      }

      &::before,
      &::after {
        opacity: 0;
        transition: all .35s ease-in-out;
      }

      @media (min-width: $breakpoint-md) {
        &:hover {

          &::before,
          &::after {
            opacity: 1;
          }
        }
      }

      svg {
        margin: 0 5px;
      }
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}