@import '../../styles/variables';

.Landing {
  text-align: center;
  position: absolute;
  right: 0;

  @media (min-width: $breakpoint-md) {
    left: 10%;
    right: auto;
  }

  img {
    transition: all .5s ease-in-out;
    transform: rotateX(180deg);

    @media (min-width: $breakpoint-md) {
      transform: rotateZ(110deg);
    }
  }

  p {
    padding: 10px;
  }
}