@import '../../styles/variables';

.Sidebar {
  flex: 1 0 0;

  @media (min-width: $breakpoint-md) {
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 2;
    background: #fff;
    border-right: 1px solid #ccc;
  }

  nav {
    display: flex;
    flex-direction: column;
  }

  &.menu-visible {
    .SidebarContentWrapper {
      height: 100%;
      padding-top: 15px;
      margin-top: 60px;
      opacity: 1;
      transform: translateX(0);
    }
  }

  .SidebarContentWrapper {
    height: 0;
    opacity: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: white;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    z-index: 2;

    @media (min-width: $breakpoint-md) {
      height: 100%;
      opacity: 1;
      transform: none;
    }
  }

}