@import '../../styles/variables';

@mixin FixedOverlay {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100vw;
  height: 100vh;
  z-index: 200;
}

@mixin Center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Loading-Overlay {
  @include FixedOverlay();

  &.ProviderError {
    background: black;
    transition: all 0.2s ease-in-out;

    img {
      filter: invert(100%);
    }

    p {
      color: red;
    }
  }

  &-Success {
    display: none !important;
    @include FixedOverlay();

    .Show-Overlay-Success & {
      display: block !important;
    }

    .Content {
      @include Center();
    }
  }

  .Async-Update & {
    display: block !important;
  }

  .No-Async-Update & {
    display: none !important;
  }

  .Content {
    @include Center();

    img {
      width: 90vw;

      @media (min-width: $breakpoint-md) {
        width: initial;
      }

      &.Pulse {
        animation: flickerAnimation 2s infinite;
      }
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}