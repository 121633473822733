@import url('https://fonts.googleapis.com/css?family=Courgette');
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './variables';

* {
  font-family: 'Courgette', sans-serif;
}

::-webkit-scrollbar {
  width: 0px;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
}

// Reset Bootstrap
.btn {
  &:hover {
    color: map-get($colors, btn-default);
  }
}

.bttn {
  position: relative;
  display: inline-block;
  width: 270px;
  border: 1px solid;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  transition: 0.5s all ease;
  color: map-get($colors, btn-default-active);
  background-color: map-get($colors, btn-default);
  border-color: map-get($colors, btn-default-active);

  @media (min-width: $breakpoint-md) {

    &:hover,
    &:focus {
      color: map-get($colors, btn-default);
      box-shadow: inset 0 -3.25em 0 0 map-get($colors, btn-default-active);
    }
  }


  &.bttn-blue {
    color: map-get($colors, btn-blue);
    border-color: map-get($colors, btn-blue);

    @media (min-width: $breakpoint-md) {

      &:hover,
      &:focus {
        color: map-get($colors, btn-default);
        box-shadow: inset 0 -3.25em 0 0 map-get($colors, btn-blue);
      }
    }

  }

  &.bttn-navy {
    color: map-get($colors, btn-navy);
    border-color: map-get($colors, btn-navy);

    @media (min-width: $breakpoint-md) {

      &:hover,
      &:focus {
        color: map-get($colors, btn-default);
        box-shadow: inset 0 -3.25em 0 0 map-get($colors, btn-navy);
      }
    }

  }

  &.bttn-aqua {
    color: map-get($colors, btn-aqua);
    border-color: map-get($colors, btn-aqua);

    @media (min-width: $breakpoint-md) {

      &:hover,
      &:focus {
        color: map-get($colors, btn-default);
        box-shadow: inset 0 -3.25em 0 0 map-get($colors, btn-aqua);
      }
    }

  }

  &.bttn-teal {
    color: map-get($colors, btn-teal);
    border-color: map-get($colors, btn-teal);

    @media (min-width: $breakpoint-md) {

      &:hover,
      &:focus {
        color: map-get($colors, btn-default);
        box-shadow: inset 0 -3.25em 0 0 map-get($colors, btn-teal);
      }
    }

  }

  &.bttn-olive {
    color: map-get($colors, btn-olive);
    border-color: map-get($colors, btn-olive);

    @media (min-width: $breakpoint-md) {

      &:hover,
      &:focus {
        color: map-get($colors, btn-default);
        box-shadow: inset 0 -3.25em 0 0 map-get($colors, btn-olive);
      }
    }

  }

  &.bttn-green {
    color: map-get($colors, btn-green);
    border-color: map-get($colors, btn-green);

    @media (min-width: $breakpoint-md) {

      &:hover,
      &:focus {
        color: map-get($colors, btn-default);
        box-shadow: inset 0 -3.25em 0 0 map-get($colors, btn-green);
      }
    }

  }

  &.bttn-lime {
    color: map-get($colors, btn-lime);
    border-color: map-get($colors, btn-lime);

    @media (min-width: $breakpoint-md) {

      &:hover,
      &:focus {
        color: map-get($colors, btn-default);
        box-shadow: inset 0 -3.25em 0 0 map-get($colors, btn-lime);
      }
    }

  }
}

.error-message {
  display: block;
  margin: 5px 0;
  color: map-get($colors, error);
  font-size: .75rem;
}