.MangaDetails {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  max-width: 100%;
  background: #fff;
  z-index: 1;
  opacity: 1;
  transition: 1s all;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  >* {
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 1.25rem;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 250px;
    margin: 0 auto;
  }

  .collaboration {
    flex-direction: column;
    padding: 10px;
    text-align: center;

    span {
      margin-left: 5px;
    }
  }

  .MangaForm {
    flex-direction: column;
    align-items: center;

    .field-label {
      span {
        display: block;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .BtnExternal {
    margin: 15px auto;

    span {
      margin: 0 5px;
    }
  }
}