/* MangaSidebar */
@import "../../../styles/variables";

.SingleSerie {
  position: relative;
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;

  button {
    position: relative;
    display: block;
    min-width: 100%;
    min-height: 150px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;

    @media (min-width: $breakpoint-md) {
      transition: 0.5s all ease-in-out;
      transform: scale(0.9);

      &:hover {
        transform: scale(1);
      }
    }

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      color: #000;
      opacity: 0.9;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .dropped-banner {
    width: calc(100% - 20px);
    top: 50%;
    transform: rotate(-10deg);
    background: rgba(map-get($colors, error), $alpha: 0.7);
    color: white;
    text-align: center;
  }
}
