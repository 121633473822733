@import '../../styles/variables';

.BackToTop {
  position: fixed;
  bottom: 1em;
  right: 0;
  transition: all .5s ease-in-out;
  opacity: 0;

  &.visible {
    opacity: 1;
  }

  button {
    width: auto;
  }
}