@import '../../styles/variables';

.Downloader {
  width: 100%;
  height: calc(100vh - 50px);

  .title {
    font-size: 1.25rem;
    text-align: center;
  }

  .Volumes,
  .Singles {
    padding: 10px;
    flex: 0 0 100%;
    max-width: 100%;

    @media (min-width: $breakpoint-md) {
      &.Full {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &:not(.Full) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    ul {
      margin: 0 auto;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 25px;

      &.grid {
        display: grid;
        grid-template-columns: 50% 50%;

        @media (min-width: $breakpoint-md) {
          grid-gap: 10px;
        }
      }

      @media (min-width: $breakpoint-md) {
        height: calc(100vh - 50px);
      }

      li {
        margin: 10px 0;
        margin-left: 2.5%;
        margin-right: 2.5%;
        text-align: center;

        .center {
          max-width: 150px;
          margin: 0 auto;

          a {
            display: block;
          }

          img {
            display: block;
            width: 100%;
            margin: 0 auto;
            opacity: 1;
            transition: all ease-in-out 0.5s;

            &[data-src],
            &.placeholder {
              opacity: 0.75;
              width: 50%;
            }

            &[data-loading] {
              width: 75%;
              animation: flickerAnimation 1s infinite;
            }
          }
        }
      }
    }
  }

  .Volumes {
    ul {
      padding: 10px;
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}