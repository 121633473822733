$colors: (
  light-grey: #d3d3d3,
  lighter-grey: #eee,
  btn-default: #fff,
  btn-default-active: #000,
  btn-blue: #0074d9,
  btn-navy: #001f3f,
  btn-aqua: #7fdbff,
  btn-teal: #39cccc,
  btn-olive: #3d9970,
  btn-green: #2ecc40,
  btn-lime: #01ff70,
  error: #FE7F2D
);

$breakpoint-md: '768px';

$background-gradient: linear-gradient(
  to bottom,
  map-get($colors, lighter-grey) 0,
  map-get($colors, light-grey) 100%
);

@mixin SidebarButton {
  color: white;
  filter:drop-shadow(2px 2px 5px rgba(0,0,0,.5));
  transition: none !important;
}