@import '../../../styles/variables';

.SidebarHeader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100vw;
  background: $background-gradient;
  z-index: 3;

  h1 {
    margin: 0;
  }

  a {
    display: block;
    width: 75%;
    color: inherit;
    text-decoration: none;

    @media (orientation: landscape) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      max-width: 280px;
      margin: 0 auto;
    }
  }

  .SidebarMenuToggler {
    @include SidebarButton();
    // position: absolute;
    // top: 100%;
    // right: 0;
    // height: 100%;
    // transform: translateY(-100%);
    color: white;
    background-color: transparent;
    font-size: 1.75em;
  }

  @media (min-width: $breakpoint-md) {
    position: initial;

    a {
      width: 100%;
    }

    .SidebarMenuToggler {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
  }
}