@import '../../styles/variables';

.MangaReader {
  position: relative;
  padding-top: 15px;

  @media (min-width: $breakpoint-md) {
    flex: 3 0 0;
    padding: 0;
    max-height: 100vh;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .fade {
    &-enter {
      opacity: 0.01;

      &.fade-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
      }
    }

    &-exit {
      opacity: 1;

      &.fade-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
      }
    }
  }
}